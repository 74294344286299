import $ from "jquery";
// import "magnific-popup";
import "slick-carousel";
import Cookies from "js-cookie";
import { ShareHandler } from "./share";
export default class UIBindings {
    formSubmitButton() {
        $(document).on('submit', 'form', function() {
            let submitText = $(this).find('[data-submit-text]');
            let submittingText = $(this).find('[data-submitting-text]');
            let btn = submitText.closest('button');
            submittingText.removeClass('d-none');
            submitText.addClass('d-none');
            btn.prop('disabled', true);
        });
    }
    magnificPopup() {
        let image = $('[mfp-type="image"]');
        let gallery = $('[mfp-type="gallery"]');
        if (image.length > 0) {
            image.magnificPopup({ type: "image" });
        }
        if (gallery.length > 0) {
            gallery.magnificPopup({
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: true,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    tCounter: '%curr% of %total%',
                    preload: [0, 2] // Will preload 0 - before current, and 1 after the current image
                },
                zoom: {
                    enabled: true,
                    duration: 500 // don't foget to change the duration also in CSS
                }
            });
        }
    }
    slickCarourel() {
        let carousel = $('[data-slick]');
        if (carousel.length > 0) {
            carousel.slick();
        }
    }
    navDrop() {}
    scientistsSearch() {
        $.ajax({
            url: 'https://api.sacnasp.org.za/api/scientists',
            type: 'GET',
            success: function(data, response) {
                var provinceField = "";
                var practiseField = "";
                var regNoField = "";
                var surnameField = "";
                $.each(data.data, function(index, value) {});
            }
        });
    };
    scientists() {
        const urlParams = new URLSearchParams(window.location.search);
        const keywords = urlParams.get('q');
        const province = urlParams.get('province');
        const field_id = urlParams.get('field_id');
        const page = urlParams.get('page');
        const domain = 'https://www.sacnasp.org.za/';


        const url = new URL('https://api.sacnasp.org.za/api/scientists');

        if(keywords) {
            url.searchParams.append('search', keywords);
        }

        if(province) {
            url.searchParams.append('province', province);
        }

        if(field_id) {
            url.searchParams.append('field_id', field_id);
        }

        if(page) {
            url.searchParams.append('page', page);
        }

        // console.log(keywords);
        $.ajax({
            url: url.href,
            type: 'GET',
            success: function(data, response) {
                // console.log("form data", data);
                // console.log("form data", response);
                var dataBlocks = "";
                var pagination = "";
                var paramKeywords = 'q=' + (urlParams.get('q') !== null ? urlParams.get('q') : '');
                var paramProvince = 'province=' + (urlParams.get('province') !== null ? urlParams.get('province') : '');
                var paramField_id = 'field_id=' + (urlParams.get('field_id') !== null ? urlParams.get('field_id') : '');
                $.each(data.data, function(index, value) {
                    dataBlocks += '<div class="col-md-12 py-2">';
                    dataBlocks += '<div class="card p-5 mt-3 bg-light h-100 mb-3 mt-5">';
                    dataBlocks += '<div class="card-body pt-0 px-0 border-bottom pb-3">';
                    dataBlocks += '<header>';
                    dataBlocks += '<h3 class="card-title font-weight-normal text-tertiary mb-0">' + value.title + '  ' + value.first_names + " " + value.surname + '</h3>';
                    dataBlocks += '</header>';
                    dataBlocks += '<div class="card-text d-flex flex-wrap flex-row ">';
                    // dataBlocks += '<div class="text-dark font-weight-bold w-100">'+value.fields[0].field +'</div>';
                    dataBlocks += '<div class="text-dark  w-100"><small>Registration No. ' + value.reg_num + '</small></div>';

                    if(value.public_detail) {
                        dataBlocks += '<div class="w-100"><pre style="width: 100%;white-space: pre-wrap;font-family: inherit;font-size: 12px;">' + value.public_detail + '</p></div></div>';
                    } else {
                        dataBlocks += '</div>';
                    }

                    dataBlocks += '</div>';
                    dataBlocks += '<div class="d-flex flex-wrap flex-row">';
                    $.each(value.fields, function(i, field) {
                        dataBlocks += '<div class="w-100 py-2" style="line-height:1">';
                        dataBlocks += '<i class="fas fa-user-graduate mr-2 fa-fw"></i>' + field.category + '<br>';
                        dataBlocks += '<i class="fas fa-book mr-2 fa-fw"></i> <small>' + field.field + '</small>';
                        dataBlocks += '</div>';
                    });
                    dataBlocks += '</div>';
                    dataBlocks += '</div>';
                    dataBlocks += '</div>';
                });

                if (data.total > data.per_page) {
                    pagination += '<nav aria-label="Page navigation">';
                    pagination += '<ul class="pagination">';

                    if (data.current_page !== 1) {
                        pagination += '<li class="page-item">' +
                            '<a class="page-link"' +
                                'href="' + domain + 'scientists?' + paramKeywords + '&' + paramProvince + '&' + paramField_id + '&page=1">' +
                                'First' +
                            '</a>' +
                        '</li>';
                    } else {
                        pagination += '<li class="page-item"><span class="page-link page-link--span">First</span></li>';
                    }

                    if (data.prev_page_url) {
                        pagination += '<li class="page-item">' +
                            '<a class="page-link"' +
                                'href="' + domain + 'scientists?' + paramKeywords + '&' + paramProvince + '&' + paramField_id + '&page=' + (data.current_page - 1) + '">' +
                                'Previous' +
                            '</a>' +
                        '</li>';
                    } else {
                        pagination += '<li class="page-item"><span class="page-link page-link--span">Previous</span></li>';
                    }

                    for (let index = data.current_page - 5; index < data.current_page; index++) {
                        if (index > 0) {
                            pagination += '<li class="page-item">' +
                                '<a class="page-link"' +
                                    'href="' + domain + 'scientists?' + paramKeywords + '&' + paramProvince + '&' + paramField_id + '&page=' + index + '">' +
                                    index +
                                '</a>' +
                            '</li>';
                        }
                    }
                    pagination += '<li class="page-item"><span class="page-link page-link--active">' + data.current_page + '</span></li>';
                    for (let index = data.current_page + 1; index < (data.current_page + 6); index++) {
                        if (index < data.last_page) {
                            pagination += '<li class="page-item">' +
                                '<a class="page-link"' +
                                    'href="' + domain + 'scientists?' + paramKeywords + '&' + paramProvince + '&' + paramField_id + '&page=' + index + '">' +
                                    index +
                                '</a>' +
                            '</li>';
                        }
                    }

                    if (data.next_page_url) {
                        pagination += '<li class="page-item">' +
                            '<a class="page-link"' +
                                'href="' + domain + 'scientists?' + paramKeywords + '&' + paramProvince + '&' + paramField_id + '&page=' + (data.current_page + 1) + '">' +
                                'Next' +
                            '</a>' +
                        '</li>';
                    } else {
                        pagination += '<li class="page-item"><span class="page-link page-link--span">Next</span></li>';
                    }

                    if (data.current_page !== data.last_page) {
                        pagination += '<li class="page-item">' +
                            '<a class="page-link"' +
                                'href="' + domain + 'scientists?' + paramKeywords + '&' + paramProvince + '&' + paramField_id + '&page=' + data.last_page + '">' +
                                'Last' +
                            '</a>' +
                        '</li>';
                    } else {
                        pagination += '<li class="page-item"><span class="page-link page-link--span">Last</span></li>';
                    }

                    pagination += '</ul>';
                    pagination += '</nav>';
                } else if (data.total === 0) {
                    dataBlocks = '<div class="alert alert-warning" role="alert">Sorry, we couldn\'t find any results.';
                    dataBlocks += '</div>';
                }
                $('.results_count').html("This search has " + data.total + " results");
                $('.scientistsBlock').html(dataBlocks);
                $('.pagination').html(pagination);
            },
            error: function(error) {
                var dataBlocks = "";
                dataBlocks += '<div class="alert alert-danger" role="alert">Error. Please ensure your search term contains more than two characters.';
                dataBlocks += '</div>';
                $('.scientistsBlock').html(dataBlocks);
                console.log(error);
            }
        });
    };
    scrollDown() {
        let click = $('#scroll-down');
        let targetDiv = $('#alerter');
        click.on('click', function() {
            $('html, body').animate({
                scrollTop: targetDiv.offset().top
            }, 2000);
        });
        let closeAlerts = $('.close-alerts');
        let alerter = $('.alerter');
        let slider = $('#alerts-slider');
        let slides = slider.find('[data-cookie]');
        closeAlerts.on('click', function() {
            let slide = $(this).closest('[data-cookie]');
            let alertCookie = slide.data('cookie')
            console.log(slide.data('slickIndex'), alertCookie);
            let alertCookies = Cookies.get('alertCookies');
            if (!alertCookies) {
                alertCookies = [];
            } else {
                alertCookies = JSON.parse(alertCookies);
            }
            console.log('alertCookies', alertCookies);
            alertCookies.push(alertCookie)
            Cookies.set('alertCookies', JSON.stringify(alertCookies), { expires: 365 });
            slider.slick('slickRemove', slide.data('slickIndex')).slick('refresh');
        });
    };
    bootstrap() {
        $('[data-toggle="tooltip"]').tooltip();
    }
    share() {
        const shareHandler = new ShareHandler();
        shareHandler.build();
    }
}